@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-Thin.ttf') format('ttf');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-UltraLight.ttf') format('ttf');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-ExtraBold.ttf') format('ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'gilroy';
  src: url('./fonts/Gilroy/Gilroy-Heavy.ttf') format('ttf');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'BubbleSans';
  src: url('../Images/curiobit/fonts/Bubblegum_Sans/BubblegumSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'LuckiestGuy';
  src: url('../Images/curiobit/fonts/Luckiest_Guy/LuckiestGuy-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas_Neue';
  src: url('../Images/curiobit/fonts/Bebas_Neue/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../Images/curiobit/fonts/Montserrat/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'WendyOne';
  src: url('../Images/curiobit/fonts/WendyOne-Regular.ttf') format('truetype');
}


html {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background: #fefefe;
  font-family: 'gilroy', 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #0a0a0a;
  -webkit-font-smoothing: antialiased;
}

.screen {
  height: 20px;
  max-width: 300px;
  font-size: 12px;
  border-radius: 5px;
  background: rgb(218, 218, 218);
  width: 100%;
  transform: rotateX(-30deg) scale(1.2);
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.footer {
  height: 60px;
  width: 70%;
  position: fixed;
  bottom: 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: rgb(86, 86, 86);
  color: white;
  margin-bottom: 40px;
}

.seat {
  margin: 3px;
  border-radius: 8px;
  background: rgba(255, 255, 255);
}

.seat--reserved {
  background: rgba(82, 80, 80);
}

.seat:hover {
  background-color: rgb(8, 77, 123) !important;
}

.seat--selected {
  background-color: rgb(0, 116, 194);
  border-radius: 8px;
}

.seat-picker {
  margin: auto;
}

.seat-picker__row__number {
  color: black;
}

.carouselImg {
  height: 34rem;
  object-fit: cover;
}

.para {
  font-size: 12px;
}

.accordion-content {
  /* overflow: hidden; */
  display: none;
  max-height: 0px;
  /* transition: all 1.9s ease-out; */
}

.accordion-content[aria-expanded='true'] {
  overflow: hidden;
  /* display: none; */
  max-height: 100vh;
  /* transition: all 2s ease-in; */
}

@media screen and (max-width: 480px) {
  .screen {
    width: 70%;
  }

  .seat-content {
    width: 288px;
    overflow: scroll !important;
  }

  .footer {
    width: 97%;
    position: fixed;
    bottom: 0;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgb(86, 86, 86);
    color: white;
    margin-bottom: 40px;
    padding: 0 4px;
  }
}
