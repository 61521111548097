
.delivery-progress-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.delivery-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  animation: fadeIn 0.5s ease-in-out;
}

.delivery-step span {
  margin-top: 8px;
  font-size: 14px;
  text-transform: capitalize;
}
.delivery-step:hover .text-green-500,
.delivery-step:hover .text-gray-300 {
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes growProgressBar {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.map-container {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.8s ease-in-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.back-home-button {
  display: inline-block;
  text-align: center;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #4caf50;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.back-home-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.back-home-button:active {
  transform: translateY(0);
  box-shadow: none;
}