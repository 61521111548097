.floating-book-a {
    transform: translatey(0px);
      animation: floating-book 6s ease-in-out infinite;
  }
  
  @keyframes floating-book {
      0% {
          transform: translatey(0px) rotateX(0deg);
      }
      50% {
          transform: translatey(-20px) rotateX(30deg);
      }
      100% {
          transform: translatey(0px)  rotateX(0deg);
      }
  }
  